<template>
    <v-container fluid align="center">

      <v-row class="d-flex justify-center" id="row_buscador">
        <v-col :cols="12">
            <v-select
                v-model="selectedType"
                :items="resultsTypes"
                :item-text="'tipoResult'"
                :item-value="'tipoResultValor'"
                label="Selecciona"
                return-object 
                single-line
                v-on:change="changeTipoResult">
            </v-select>
        </v-col>
        <v-col :cols="12">
            <p>{{selectedTag == null ? 'No hay etiqueta activa' : selectedTag.className}}</p>
        </v-col>
        <v-col :cols="12">
            <v-text-field
                v-model="buscador"
                label="Buscar etiqueta"
                clearable
                outlined
                @input="buscarEtiqueta(buscador)"
            ></v-text-field>
        </v-col>
      </v-row>
      <v-row class="d-flex justify-center two-rows-height">
        <v-col :cols="12">
            <v-card class="min-height scroll">
                <v-card-title class="justify-center">
                    <p>Tags usados</p>
                </v-card-title>
                <v-card class="tableTagContainer">
                    <v-radio-group v-model="selectedTag" v-on:change="functionSelectedUsedTag()">
                        <v-table>
                            <thead>
                                <tr>
                                    <th>
                                        <v-icon>
                                        mdi-tag-search
                                        </v-icon>
                                    </th>
                                    <th>
                                        <v-icon>
                                        mdi-eye-off
                                        </v-icon>
                                    </th>
                                    <th>
                                        Color
                                    </th>
                                    <th>
                                        [{{ totalUsedTags }}]
                                    </th>
                                    <th>
                                        Etiquetas
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(tag, index) in usedTagsVal_filter">
                                    <td>
                                        <v-radio
                                            :key="index"
                                            :value="tag"
                                            :color="tag.color"
                                        ></v-radio>
                                    </td>
                                    <td>
                                        <v-simple-checkbox
                                            :color="tag.color"
                                            :value="tag"
                                        ></v-simple-checkbox>
                                    </td>
                                    <td>
                                        <v-chip
                                            class="ma-2"
                                            :color="tag.color"
                                            label
                                        >
                                            <v-icon> mdi-label</v-icon>   
                                        </v-chip>
                                    </td>
                                    <td>
                                        {{ tag.usedCount }}
                                    </td>
                                    <td>
                                        {{tag.className}}
                                    </td>
                                </tr>
                            </tbody>
                        </v-table>
                    </v-radio-group>
                </v-card>
            </v-card>
        </v-col>
      </v-row>
      <v-row class="d-flex justify-center two-rows-height" >
        <v-col :cols="12">
            <v-card class="min-height scroll">
                <v-card-title class="justify-center">
                    <p>Tags no usados</p>
                </v-card-title>
                <v-card class="tableTagContainer">
                    <v-radio-group v-model="selectedTag" v-on:change="functionSelectedNotUsedTag()">
                        <v-table>
                            <thead>
                                <tr>
                                    <th>
                                        <v-icon>
                                        mdi-tag-search
                                        </v-icon>
                                    </th>
                                    <th>
                                        Color
                                    </th>
                                    <th>
                                        Etiquetas
                                    </th>
                                </tr>
                             </thead>
                            <tbody>
                                <tr v-for="(tag, index) in notUsedTagsVal_filter">
                                    <td>
                                        <v-radio
                                            :key="index"
                                            :value="tag"
                                        ></v-radio>
                                    </td>
                                    <td>
                                        <v-chip
                                            class="ma-2"
                                            :color="tag.color"
                                            label
                                        >
                                            <v-icon> mdi-label</v-icon>   
                                        </v-chip>
                                    </td>
                                    <td>
                                        {{tag.className}}
                                    </td>
                                </tr>
                            </tbody>
                        </v-table>
                    </v-radio-group>
                </v-card>
            </v-card>
        </v-col>
      </v-row>

    </v-container>
</template>

<style scoped>
    .v-card__title, p, .v-card__text > .v-input--radio-group{
        padding-top: 0;
        padding-bottom: 3px;
        margin-top: 0;
        margin-bottom: 0;
    }

    .tableTagContainer > div {
        padding-top: 0;
        margin-top: 0;
    }

    .tableTagContainer thead {
      position: sticky;
      top: 0;
      z-index: 2;
      background-color: white;
    }
</style>

<script>
import '@/assets/css/tagslist.css'
import Vue from 'vue'
import Message from '@/helpers/message.js'
import {Tag} from "@/model/Tag.js"

export default {
        name: 'TagsLists',
        props: {
            tagsUsed: {
                tyoe: Array,
                default: null
            },
            tagsNotUsed: {
                tyoe: Array,
                default: null
            },
            resultsTypes: {
                tyoe: Array,
                default() {  return [{tipoResult:'Elementos',tipoResultValor:'elements'},
                            {tipoResult:'Predicciones',tipoResultValor:'predictions'},
                            {tipoResult:'Extras',tipoResultValor:'extras'}]
                }
            }
        }, 
        created: function () {
        }, 
        data:() =>({
             usedTagsVal: [],
             notUsedTagsVal: [],
             usedTagsVal_filter: [],
             notUsedTagsVal_filter: [],
             selectedTag: null,
             buscador: null,
             selectedType:{tipoResult:'Predicciones',tipoResultValor:'predictions'},
             resultsType:null,
             totalUsedTags:0,
        }),
        mounted () {
            window.addEventListener("resize", this.setTagsDimensions);
            this.setTagsDimensions()
        },
        destroyed() {
            window.removeEventListener("resize", this.setTagsDimensions);
        },
        methods: {
            setTagsDimensions(e) {
                var containerCanvas_height = document.getElementById('containerCanvas').clientHeight;
                var row_buscador_height = document.getElementById('row_buscador').clientHeight;

                var _height = ((containerCanvas_height - row_buscador_height)/2);

                var rowCard = document.getElementsByClassName("two-rows-height");
                for(var i=0;i<rowCard.length;i++){
                    rowCard[i].style.maxHeight = _height + 'px';
                    rowCard[i].style.height = _height + 'px';
                }
                var cardClass = document.getElementsByClassName("scroll");
                for(var i=0;i<cardClass.length;i++){
                    cardClass[i].style.maxHeight = (_height - 10) + 'px';
                    cardClass[i].style.height = (_height - 10) + 'px';
                }
            },
            functionUpdateTagsUsed (usedTag) {
                if (usedTag !== "undefined" && usedTag !== null) {
                    this.usedTagsVal=[];
                    for (const tag in usedTag) {
                        this.usedTagsVal.push({
                            id:usedTag[tag].local_id,
                            className:usedTag[tag].name,
                            visible:true,
                            styleColor: "background-color: rgba("+usedTag[tag].color.red+", "+usedTag[tag].color.green+", "+usedTag[tag].color.blue+",1)",
                            color: "rgba("+usedTag[tag].color.red+", "+usedTag[tag].color.green+", "+usedTag[tag].color.blue+",.5)",
                        });
                    }
                    this.usedTagsVal_filter = this.usedTagsVal;
                }
            },
            functionUpdateTagsNotUsed (notUsedTag) {
                if (notUsedTag !== "undefined" && notUsedTag !== null) {
                    this.notUsedTagsVal=[];
                    for (const tag in notUsedTag) {
                        this.notUsedTagsVal.push({
                            id:notUsedTag[tag].local_id,
                            className:notUsedTag[tag].name,
                            styleColor: "background-color: rgba("+notUsedTag[tag].color.red+", "+notUsedTag[tag].color.green+", "+notUsedTag[tag].color.blue+",1)",
                            color: "rgba("+notUsedTag[tag].color.red+", "+notUsedTag[tag].color.green+", "+notUsedTag[tag].color.blue+",.5)",
                        });
                    }
                    this.notUsedTagsVal_filter = this.notUsedTagsVal;
                }
            },

            functionSelectedTag(){
                this.$emit('eventUpdateTagSelected', this.selectedTag)
            },
            buscarEtiqueta(val){
                let _usedTagsVal = this.usedTagsVal;
                let _notUsedTagsVal = this.notUsedTagsVal;

                if(val == null){
                    this.usedTagsVal_filter = _usedTagsVal;
                    this.notUsedTagsVal_filter = _notUsedTagsVal;
                } else {

                    var buscador_useTag = [];
                    var buscador_notUseTag = [];

                    for (const useTag in _usedTagsVal) {

                        if(_usedTagsVal[useTag]['id'] === val || _usedTagsVal[useTag]['className'].toLowerCase().includes(val.toLowerCase())){
                            buscador_useTag.push(_usedTagsVal[useTag]);
                        }

                    }

                    for (const useTag in _notUsedTagsVal) {

                        if(_notUsedTagsVal[useTag]['id'] === val || _notUsedTagsVal[useTag]['className'].toLowerCase().includes(val.toLowerCase())){
                            buscador_notUseTag.push(_notUsedTagsVal[useTag]);
                        }

                    }

                    this.usedTagsVal_filter = buscador_useTag;
                    this.notUsedTagsVal_filter = buscador_notUseTag;
                }
            },
            functionSearchSelectedTag (tagsArray, selectedTag) { //Esta función busca y coloca en el canvas el activeTag para poder crear y modificar el Tag
                let tagFinded=new Tag();
                let canvas=Vue.prototype.$canvas;
                let tagR=0;
                let tagG=0;
                let tagB=0;
                if (canvas!== "undefined" && canvas!==null) {
                    for (const indexTag in tagsArray) {
                        if (tagsArray[indexTag].local_id === selectedTag.id) {
                            tagFinded.id=tagsArray[indexTag].local_id;
                            tagFinded.className=tagsArray[indexTag].name;
                            tagR=tagsArray[indexTag].color.red;
                            tagG=tagsArray[indexTag].color.green;
                            tagB=tagsArray[indexTag].color.blue;
                            let arrayRGBColor=new Array();
                            arrayRGBColor.push(tagR);
                            arrayRGBColor.push(tagG);
                            arrayRGBColor.push(tagB);
                            tagFinded.arrayRGBColor=arrayRGBColor;
                            canvas.activeTag=tagFinded;
                        }
                    }
                }
            },
            changeTipoResult: function () {
                this.$emit('eventUpdateSelectedResult', this.selectedType)
            },
            functionSelectedUsedTag(){
                this.functionSearchSelectedTag(this._props.tagsUsed,this.selectedTag);
            },
            functionSelectedNotUsedTag(){
                this.functionSearchSelectedTag(this._props.tagsNotUsed,this.selectedTag);
            }
        },
        watch : {
            tagsUsed:function(val) {
                this.functionUpdateTagsUsed(Vue.prototype.$canvas.listUsedTags)
            },
            tagsNotUsed:function(val) {
                this.functionUpdateTagsNotUsed(val)
            },
        }
}
</script>