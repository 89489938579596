<template>
    <v-container fluid align="center" class="card min-height" id="containerCanvas">

      <v-row class="d-flex justify-center">
        <v-col>
            <canvas id="canvas_automata"></canvas>
          </v-col>
      </v-row>

    </v-container>
</template>

<script>


import 'bootstrap-vue/dist/bootstrap-vue.css'
import '@/assets/css/canvas.css'
import "@/model/PercepthorCanvas.js"
import "@/model/PercepthorImage.js"
import "@/helpers/fileSystemAPI.js"
import {Tag} from "@/model/Tag.js"

import { Buffer } from 'buffer'
import  { fabric } from 'fabric'
import Vue from 'vue'
import  axios  from 'axios'
import Message from '@/helpers/message.js'

import { loadImage } from '@/helpers/fileSystemAPI.js'
import { PercepthorArticle } from '@/model/PercepthorArticle'
import { PercepthorResultSet } from '@/model/PercepthorResult.js'
import { Coordinate } from '@/model/Coordinate.js'
import { Result } from '@/model/Result'
import PercepthorCanvas from '@/model/PercepthorCanvas.js'
import PercepthorImage from '@/model/PercepthorImage.js'

export default {
        name: 'PCanvas',
        props: {
            tagSelected: {
                tyoe: Array,
                default: null
            },
            tipoResultValor: {
                tyoe: String,
                default: 'predictions'
            },
            requireNextTicket: {
                tyoe: Boolean,
                default: false,
            }
        },
        data:() =>({
            actualImg:0,
            allImg:0,
            isTagChecked:true,
            resultsType:null,
            changeObjects:[],
            tipoResultValorPrev:null,
        }),
        mounted() {
            const canvas = new PercepthorCanvas('canvas_automata');
            canvas.hoverCursor = 'default';
            canvas.isTagChecked=true;
            canvas.stopContextMenu = true;
            canvas.fireRightClick = true;
            canvas.activeTag = null;
            canvas.setTriggerUpdateQuantityTags=false;
            canvas.canvasChange=[];
            //canvas.perceptor
            window.addEventListener("resize", this.setCanvasDimensions);
            canvas.eventoTag = new Event ('tagChange');
            document.addEventListener('tagChange',(e)=> {
                this.isTagChecked=false;
            },false);

            canvas.setWidth(canvas.wrapperEl.parentElement.parentElement.clientWidth-10);
            canvas.setHeight(canvas.wrapperEl.parentElement.parentElement.clientHeight-5);
            Vue.prototype.$canvas=canvas; 
            /*if (sessionStorage.getItem('readyForTickets')) {
                if (sessionStorage.getItem('actual_oid_Img') === "undefined" || sessionStorage.getItem('actual_oid_Img') === null ) {
                                    self.getNextTicket();
                                } else {
                                    self.getImageData(); 
                                }
            }*/
            
        },
        created: function () {
            let self=this;
            sessionStorage.setItem("ticketsWaiting",0);
            sessionStorage.setItem("readyForTickets",false);
        },
        destroyed() {
            window.removeEventListener("resize", this.setCanvasDimensions);
        },
        methods: {
            setTagChecked () {
                this.isTagChecked=false;
            },
            setCanvasDimensions(e) {
                const canvas = Vue.prototype.$canvas;
                canvas.setWidth(0);
                canvas.setHeight(0);
                canvas.setWidth(canvas.wrapperEl.parentElement.parentElement.clientWidth-10);
                canvas.setHeight(canvas.wrapperEl.parentElement.parentElement.clientHeight-10);
                canvas.setFitZoom();
            },
            getNextTicket:function () {
                let self=this;
                axios.get("/api/tickets/next", {
                    params: {
                        project: sessionStorage.getItem('selProject'),
                    }
                })
                .then(async response=>{
                    sessionStorage.setItem('actual_oid_Img',response.data.image.$oid);
                    this.getImageData(true);
                })
                    
            },
            getImageData: async function (inicio = false) {
                let self=this;
                require('@/assets/js/axioshelper.js')
                axios.get("/api/images/"+sessionStorage.getItem('actual_oid_Img')+"/data", {
                    params: {
                            
                        },
                        responseType: 'arraybuffer',
                    })
                    .then(async response=>{
                        sessionStorage.setItem('actual_data_Img', "data:image/jpg;base64," + Buffer.from(response.data, 'binary').toString('base64'));
                        if (inicio) {
                            this.getAllTags()
                            this.getResults(inicio);
                        } else {
                            let imagen = await loadImage(sessionStorage.getItem('actual_data_Img'));
                            if (sessionStorage.getItem('projectTags') !== "undefined" && sessionStorage.getItem('projectTags') !== null) {
                                Vue.prototype.$proyectAllTags=JSON.parse(sessionStorage.getItem('projectTags'));
                                console.log("Precargado: ");
                                console.log(Vue.prototype.$proyectAllTags);
                            }
                            if (sessionStorage.getItem('actual_predictions') !== "undefined" && sessionStorage.getItem('actual_predictions') !== null) {
                                await this.setImage(imagen, true);
                            } else {
                                await this.setImage(imagen, false);
                            }
                        }
                        
                    })
            },
            getAllTags: function () {
                console.log("Obteniendo Tags");
                axios.get("/api/projects/"+sessionStorage.getItem('selProject')+"/tags?skip=0&limit=0", {
                    params: {
                        //Sin parámetros por ahora
                    },
                })
                .then (response=>{
                    console.log(response);
                    sessionStorage.setItem('projectTags',JSON.stringify(response.data));
                    Vue.prototype.$proyectAllTags=response.data;
                    console.log("Request: ");
                    console.log(Vue.prototype.$proyectAllTags);
                })
            },
            getResults: function (inicio = false) {
                axios.get("/api/images/"+sessionStorage.getItem('actual_oid_Img')+"/result", {
                            params: {
                                //Sin parámetros por ahora
                            },
                        })
                        .then(async response=>{
                            sessionStorage.setItem('actual_oid_Res',response.data.results[0].result.$oid)
                            console.log("getResults:")
                            console.log(response.data.results);
                            for (let contype in response.data.results) {
                                console.log(response.data.results[contype])
                                console.log(response.data.results[contype].r_type.name.split('.')[0]);
                                //this.resultsType=response.data.results[contype].r_type.name.split('.')[0];
                                sessionStorage.setItem('resultTypeOid',response.data.results[contype].r_type._id.$oid);
                                sessionStorage.setItem('resultType',response.data.results[contype].r_type.name.split('.')[0]);
                                sessionStorage.setItem('resultTypeId',response.data.results[contype].r_type.result_type);

                            }
                            //console.log(sessionStorage.getItem('actual_oid_Res'));
                            this.getInfo(inicio);
                        });
            },
            getInfo:function (inicio = false) {
                if (sessionStorage.getItem('actual_oid_Res') != "undefined" && sessionStorage.getItem('actual_oid_Res')!= null) {
                    axios.get("/api/results/"+sessionStorage.getItem('actual_oid_Res')+"/info", {
                        params: {
                                //Sin parámetros por ahora
                            },
                        })
                        .then(async response=>{
                            const contPredictions=response.data.predictions.length;
                            // console.log(response.data.predictions);
                            const canvas=Vue.prototype.$canvas;
                            sessionStorage.setItem('actual_data',JSON.stringify(response.data));
                            if (sessionStorage.getItem('resultType')==='results') {
                                sessionStorage.setItem('actual_predictions',JSON.stringify(response.data.predictions));
                                sessionStorage.setItem('actual_elements',JSON.stringify(response.data.elements));
                                sessionStorage.setItem('actual_extras',JSON.stringify(response.data.extras));
                                sessionStorage.setItem('actual_custom',JSON.stringify(response.data.custom))
                                this.tipoResultValorPrev='predictions';
                            } else {
                                sessionStorage.setItem('actual_'+sessionStorage.getItem('resultType'),JSON.stringify(response.data));
                            }
                            let imagen = await loadImage(sessionStorage.getItem('actual_data_Img'));

                            await this.setImage(imagen, inicio,this.tipoResultValor);
                            //sessionStorage.setItem('actual_oid_Res',response.data.results[0].result.$oid)
                        })
                } else {
                    //console.log("No hay resultados para cargar");
                    Message.SmallNoty("warning","No hay resultados para cargar")
                }
            },
            setImage: async function (imagen, imprimir = false,tipoResult='predictions') {
                if (imagen !== null) {
                    const imagenActual = await new fabric.PercepthorImage(imagen)
                    Vue.prototype.$imagen=imagenActual;
                    const canvas = Vue.prototype.$canvas;
                    const lowercanvas = canvas.lowerCanvasEl.getContext("2d");
                    imagenActual.set({
                        id: 1,
                        Image: imagen,
                        name: sessionStorage.getItem('actual_oid_Img'),
                        extension: 'jpg',
                        width: imagen.width,
                        height: imagen.height,

                        listResults: JSON.parse(sessionStorage.getItem('actual_'+tipoResult)),
                    })
                    canvas.setPercepthorImage(imagenActual);
                    canvas.percepthorImage.width=imagen.width;
                    canvas.percepthorImage.height=imagen.height;
                    console.log(canvas);
                    if(imprimir){
                        await this.setCanvasResults(imagenActual);
                    }
                    //actualCanvas=Vue.prototype.$canvas.percepthorImage;
                    //canvas.lowerCanvasEl.parentElement.appendChild(Vue.prototype.$canvas.percepthorImage.Image);
                    // this.setSizeCanvas(canvas);
                    //lowercanvas.drawImage(Vue.prototype.$canvas.percepthorImage.Image,0,0);
                }
            },
            checkUsedTags: function (arg) {   
                let imageTags=arg;
                const projectTags = Vue.prototype.$proyectAllTags;
                let canvas = Vue.prototype.$canvas;
                let usedTags=[];
                let notUsedTags=[];
                Vue.prototype.$canvas.listUsedTags=[];
                let bandTag=false;
                let valUsedTag=0;
                for (const contTag in projectTags.tags) {
                    bandTag=false;
                    for (const contUsedTag in imageTags) {
                        if (projectTags.tags[contTag].local_id === imageTags[contUsedTag].local_id) {
                            bandTag=true;
                            valUsedTag=contUsedTag;
                            break;
                        }
                    }
                    if (bandTag=== true) {
                        usedTags.push(imageTags[valUsedTag]);
                        Vue.prototype.$canvas.listUsedTags.push(imageTags[valUsedTag]);
                    }
                    if (bandTag === false) {
                        notUsedTags.push(projectTags.tags[contTag]);
                    }
                }
                canvas.isTagChecked=true;
                console.log(this);
                this.isTagChecked=true;
                var tags = {
                    'used': usedTags,
                    'notUsed': notUsedTags
                };
                
                this.$emit('eventUpdateTags', tags)
            },
            setCanvasResults: async function (arg) {
                let listResults=null;
                let  imagenActual=arg;
                console.log("All Tags:")
                console.log(Vue.prototype.$proyectAllTags);
                let canvas = Vue.prototype.$canvas;
                Vue.prototype.$usedTags=new Array();
                Vue.prototype.$notUsedTags=new Array();
                const projectTags = Vue.prototype.$proyectAllTags;
                const imageTags = new Array();
                if (sessionStorage.getItem('resultType')==='results') {
                    if (this.tipoResultValor === null) {
                        listResults=JSON.parse(sessionStorage.getItem('actual_predictions'));
                    } else {
                        listResults=JSON.parse(sessionStorage.getItem('actual_'+this.tipoResultValor));
                    }
                }
                this.resultsType=sessionStorage.getItem('resultType');
                //let contRes=listResults.length;
                let tag = null;
                //Coloca en el PercepthorImage los results para posteriormente usarlos
                for (const contRes in listResults) {
                    let coor = new Coordinate(
                        contRes,
                        listResults[contRes].area,
                        listResults[contRes].coords[0],
                        listResults[contRes].coords[1],
                        listResults[contRes].coords[2],
                        listResults[contRes].coords[3],
                        listResults[contRes].score,
                    )
                    let tagObjetive=listResults[contRes].clase;
                    let tagR=0;
                    let tagG=0;
                    let tagB=0;
                    let tagFinded=null;
                    let tagRequestFinded=null
                    for (const contTag in projectTags.tags) {
                        if (tagObjetive === projectTags.tags[contTag].name) {
                            tagRequestFinded=projectTags.tags[contTag];
                            tagR=projectTags.tags[contTag].color.red;
                            tagG=projectTags.tags[contTag].color.green;
                            tagB=projectTags.tags[contTag].color.blue;
                            tagFinded=new Tag();
                            tagFinded.id=projectTags.tags[contTag].local_id;
                            //tagFinded.id=Date.parse(new Date());
                            tagFinded.className=projectTags.tags[contTag].name;
                            let arrayRGBColor=new Array();
                            arrayRGBColor.push(tagR);
                            arrayRGBColor.push(tagG);
                            arrayRGBColor.push(tagB);
                            tagFinded.arrayRGBColor=arrayRGBColor;
                            break;
                        }
                    }
                    let r=new Result(Number(contRes),tagObjetive,coor);
                    imageTags.push(tagRequestFinded);
                    imagenActual?.listResults?.push(r);
                    let rgbAux=new Array();
                    rgbAux[0]=tagR;
                    rgbAux[1]=tagG;
                    rgbAux[2]=tagB;
                    let [left, top, width, height] = r.originCoordinate.getParamsForFabricPerceptorTag(imagenActual.width, imagenActual.height)
                    if (tagFinded !== null) {
                        let numPuerta=listResults[contRes].num_puerta;
                        let article = new PercepthorArticle( {
                            left: left,
                            id: tagFinded.id,
                            label: tagObjetive,
                            top: top,
                            label: null,
                            originX: 'left',
                            originY: 'top',
                            width: width - 1, //Quitamos el ancho del borde (strokeWidth)
                            height: height - 1, //Quitamos el ancho del borde (strokeWidth)
                            objectCaching: false,
                            strokeWidth: 1, //Ancho del borde, este tambien cuenta en el tamanio del objeto, en width y height
                            strokeUniform: true,
                            tag: tagFinded,
                            fill: `rgba(${rgbAux[0]}, ${rgbAux[1]}, ${rgbAux[2]},.3)`,
                            stroke: `rgba(${rgbAux[0]}, ${rgbAux[1]}, ${rgbAux[2]},1)`,
                            result: r,
                            num_puerta: numPuerta,
                            });
                        canvas.add(article);
                    } else {
                        Message.SmallNoty("error","El resultado "+tagObjetive+", no está en la lista de tags.")
                    }
                }
                this.changeObjects=canvas.canvasChange;
                //Uso de los results del PercepthorImage
                await this.checkUsedTags(imageTags);
             },
            setSizeCanvas: async function (canvas) {
                if (canvas !== null) {
                    canvas.setFitZoom();
                    const pNode=canvas.wrapperEl.parentNode;
                    const pNodeWidth=pNode.clientWidth;
                    const pNodeHeight=pNode.clientHeight;
                    const canvasdimension=new Object();
                    canvasdimension.width="100%";
                    canvasdimension.height="100%";
                    const dimensionopt=new Object();
                    dimensionopt.cssOnly=true;
                    canvas.setDimensions(canvasdimension,dimensionopt);
                    canvas.lowerCanvasEl.attributes.style.value="position: absolute; width: 100%; height: 100%; left: 0px; top: 0px; touch-action: none; user-select: none;";
                    canvas.upperCanvasEl.attributes.style.value="position: absolute; width: 100%; height: 100%; left: 0px; top: 0px; touch-action: none; user-select: none;";
                    canvas.lowerCanvasEl.width=pNodeWidth;
                    canvas.upperCanvasEl.width=pNodeWidth;
                    canvas.lowerCanvasEl.height=pNodeHeight;
                    canvas.upperCanvasEl.height=pNodeHeight;
                    canvas.lowerCanvasEl.parentElement.attributes.style.value="position: absolute; width: 100%; height: 100%; left: 0px; top: 0px; touch-action: none; user-select: none;";
                    canvas.upperCanvasEl.parentElement.attributes.style.value="position: absolute; width: 100%; height: 100%; left: 0px; top: 0px; touch-action: none; user-select: none;";
                    canvas.lowerCanvasEl.parentElement.width=pNodeWidth;
                    canvas.lowerCanvasEl.parentElement.height=pNodeHeight;
                    /*canvas.lowerCanvasEl.parentElement.parentElement.attributes.style.value="position: absolute; width: 100%; height: 100%; left: 0px; top: 0px; touch-action: none; user-select: none;";
                    canvas.lowerCanvasEl.parentElement.parentElement.width=pNodeWidth;
                    canvas.lowerCanvasEl.parentElement.parentElement.height=pNodeHeight;*/
                }
            },
            verifyNewTags: function () {
                let canvas=Vue.prototype.$canvas;
                let lastTag=canvas._objects[canvas._objects.length-1].tag;
                let usedTags=Vue.prototype.$canvas.listUsedTags;
                let findedTag=null;
                let bandTag=false;
                const projectTags = Vue.prototype.$proyectAllTags;
                for (let contTag in projectTags.tags) {
                    if (lastTag.id===projectTags.tags[contTag].local_id) {
                        findedTag=projectTags.tags[contTag];
                        bandTag=false;
                        for (let contUsedTag in usedTags) {
                            if (lastTag.id===usedTags[contUsedTag].local_id) {
                                bandTag=true;
                            }
                        }
                    }
                }
                if (!bandTag) {
                    usedTags.push(findedTag);
                    this.isTagChecked=false;
                }
                canvas.canvasChange.push(0);

            },
            verifyRemovedTags: function () {
                let canvas=Vue.prototype.$canvas;
                let usedObjects=canvas._objects;
                let usedTags=[];
                Vue.prototype.$canvas.listUsedTags=[];
                let findedTag=null;
                let bandTag=false;
                const projectTags = Vue.prototype.$proyectAllTags;
                for (const contTag in projectTags.tags) {
                    for (const contObj in usedObjects) {
                        if (usedObjects[contObj].id===projectTags.tags[contTag].local_id) {
                            if (usedObjects[contObj].tag!==undefined) {
                                usedTags.push(projectTags.tags[contTag])
                                Vue.prototype.$canvas.listUsedTags.push(projectTags.tags[contTag])
                            }
                            bandTag=true;
                            break
                        }
                    }
                }
                for (let contObj in usedObjects) {
                    console.log(usedObjects[contObj]);
                }
                if (bandTag) {
                    //usedTags.push(findedTag);
                    this.checkUsedTags(Vue.prototype.$canvas.listUsedTags);
                    this.isTagChecked=false;
                }
                canvas.canvasChange.push(0);

            },
            saveNChangeResult: async function (tipoResultValor) {
                let canvas=Vue.prototype.$canvas;
                let actualTipoResultArticles= [];
                const objectsInsideCanvas=Vue.prototype.$canvas.getObjects();
                objectsInsideCanvas.forEach((element) => {
                    if (element.type === 'percepthorArticle') {
                        let userResultSet= new PercepthorResultSet();
                        let json_obj = (element.getPropertiesYOLOFormat(canvas.percepthorImage.width, canvas.percepthorImage.height))
                        userResultSet.setClase(json_obj.className);
                        userResultSet.setArea(json_obj.area);
                        userResultSet.setCoords(json_obj.upper_left_x,json_obj.upper_left_y,json_obj.lower_right_x,json_obj.lower_right_y);
                        userResultSet.setScore(json_obj.probability);
                        userResultSet.setNumPuerta(json_obj.num_puerta);
                        actualTipoResultArticles.push(userResultSet);
                    }
                });
                if (this.tipoResultValorPrev===null && sessionStorage.getItem('resultType')==='result') {
                    sessionStorage.setItem('actual_predictions',JSON.stringify(actualTipoResultArticles));
                    this.tipoResultValorPrev='predictions';
                } else {
                    sessionStorage.setItem('actual_'+this.tipoResultValorPrev,JSON.stringify(actualTipoResultArticles));  
                }
                console.log("Valor Guardado: "+sessionStorage.getItem('actual_'+this.tipoResultValorPrev))
                canvas.removePercepthorArticles();
                console.log(tipoResultValor);
                let imagen = await loadImage(sessionStorage.getItem('actual_data_Img'));
                await this.setImage(imagen,true,tipoResultValor);
                this.tipoResultValorPrev=tipoResultValor;
            },
            cleanAll: function () {
                let canvas=Vue.prototype.$canvas;
                sessionStorage.removeItem('actual_oid_Img');
                sessionStorage.removeItem('actual_data');
                sessionStorage.removeItem('actual_data_Img');
                sessionStorage.removeItem('actual_predictions');
                sessionStorage.removeItem('actual_elements');
                sessionStorage.removeItem('actual_oid_Res')
                canvas.removePercepthorArticles();
                canvas.removePercepthorImage();
            },
        },

        watch: {
            isTagChecked: function (parametro1, parametro2){
                if (this.isTagChecked==false) {
                    this.checkUsedTags(Vue.prototype.$canvas.listUsedTags);
                }
                
            },
            tipoResultValor: function (a) {
              console.log("Valor desde canvas");
              console.log(this.tipoResultValor);  
              this.saveNChangeResult(this.tipoResultValor);
            },
            changeObjects: function  (newVal,oldVal) {
                //
                if (newVal[newVal.length-1]===1) {
                    this.verifyNewTags();              
                } else if (newVal[newVal.length-1]===2) {
                    this.verifyRemovedTags(); 
                } else if (newVal[newVal.length-1]===3) {
                    this.verifyNewTags();
                    this.verifyRemovedTags();                     
                }
            },
            requireNextTicket: function (arg) {
                if (arg) {
                  this.cleanAll();
                  this.getNextTicket();
                }
                this.$emit('eventRequireNextTicket',false);
            }

        }
}
</script>