<template>
  <v-app-bar
    dense
    dark
    width="100%"
    class="card"
  >
    <!-- <v-app-bar-nav-icon></v-app-bar-nav-icon> -->

    <v-row>
      <v-col>
        <img src="../../assets/userwhite.svg" width="28" height="28" :alt="username"/>
        <span class="ml-3"><b>{{capitalizeFirstLetter(username)}}</b> ({{rol}})</span>
      </v-col>
      <v-col>
        <span>{{Organization}} | {{Project}}</span>
      </v-col>
    </v-row>

    <v-spacer></v-spacer>

      <v-btn 
        icon
        v-on:click="salvaCanvas();"
      >
        <v-icon>mdi-cloud-download-outline</v-icon>
      </v-btn>

      <v-btn
        icon
        v-on:click="guardaCanvas();"
      >
        <v-icon>mdi-content-save</v-icon>
      </v-btn>

      <v-btn
        icon
        v-on:click="siguienteTicket();"
        :disabled='nextDisabled'
      >
        <v-icon>mdi-skip-forward</v-icon>
      </v-btn>

      <v-btn
        icon
        v-on:click="logout();"
      >
        <v-icon>mdi-logout</v-icon>
      </v-btn>

      <span>v1.0.0</span>

  </v-app-bar>
</template>

<script>

// import '@/assets/css/main.css'
// import '@/assets/css/header.css'
import Vue from 'vue'
import {PercepthorResult} from '@/model/PercepthorResult.js'
import {PercepthorResultSet} from '@/model/PercepthorResult.js'
import {ConfirmActionMessage} from '@/helpers/message.js'
import  axios, { Axios }  from 'axios'
import Message from '@/helpers/message.js'
import Router from 'vue-router';
import { faBroadcastTower } from '@fortawesome/free-solid-svg-icons'
import VueCookies from 'vue-cookies'

Vue.use(VueCookies, { 
  // options here
    })

export default {
        name: 'Header',
        props:{
          tipoResultValor: {
                tyoe: String,
                default: 'predictions'
            },
        },
        created: function () {
          require('@/assets/js/axioshelper.js');
          switch (this.role) {
              case "common": {
                this.rol="Revisor";
                break;
              }
              case "6169a0c1283fbe31af55ab82": {
                this.rol="Administrador";
                break;
              }
              case "admin": {
                this.rol="Administrador";
                break;
              }
              case "developer": {
                this.rol="Staff";
                break;
              }
              case "God": {
                this.rol="God";
                break;
              }
              default: {
                this.rol=this.role;
              }

          }
          
        },
        data:() => ({
            username:sessionStorage.username,
            role:sessionStorage.role,
            Organization:sessionStorage.selNameOrganization,
            Project:sessionStorage.selNameProject,
            nextDisabled:true,
        }),
        methods: {
          logout: function () {
            let self =this;
              if (this.nextDisabled) {
                ConfirmActionMessage.fire({
                  icon: 'warning',
                  title: 'Advertencia!',
                  text: "¿Desea salir sin guardar?, los cambios no podran ser recuperados!",
              }).then((result) => {
                  if (result.isConfirmed) {
                    this.cleanAll()
                    
                    window.location.href="https://test.login.percepthor.com";
                  }
              })
            } else {
              this.cleanAll()
              window.location.href="https://test.login.percepthor.com";
            }
          },
          cleanAll: function () {
                let canvas=Vue.prototype.$canvas;
                sessionStorage.removeItem('actual_oid_Img');
                sessionStorage.removeItem('actual_data');
                sessionStorage.removeItem('actual_data_Img');
                sessionStorage.removeItem('actual_predictions');
                sessionStorage.removeItem('actual_elements');
                sessionStorage.removeItem('actual_oid_Res');
                canvas.removePercepthorArticles();
                canvas.removePercepthorImage();
                sessionStorage.clear();
                $cookies.remove('percepthor-test-jwt','/','.percepthor.com');
            },
          capitalizeFirstLetter: function (string) {
            return string.charAt(0).toUpperCase() + string.slice(1);
          },
          salvaCanvas: function () {
            console.log("Guardando imagen del canvas");
          },
          siguienteTicket: function () {
            console.log("Siguiente Ticket");
            this.$emit('eventRequireNextTicket',true);
            //this.requireNextTicket=true;
          },
          guardaCanvas: function () {
            let failResult=false;
            const canvas=Vue.prototype.$canvas;
            const objectsInsideCanvas=Vue.prototype.$canvas.getObjects();
            let percepthorArticles = [];
            let predictionsArticles= [];
            let elementsArticles=[];
            let extrasArticles=[];
            let userResult = new PercepthorResult(sessionStorage.idorganization,sessionStorage.actual_oid_Img,sessionStorage.resultTypeOid);
            objectsInsideCanvas.forEach((element) => {
                if (element.type === 'percepthorArticle') {
                    let userResultSet= new PercepthorResultSet();

                    let json_obj = (element.getPropertiesYOLOFormat(canvas.percepthorImage.width, canvas.percepthorImage.height))
                    userResultSet.setClase(json_obj.className);
                    userResultSet.setArea(json_obj.area);
                    userResultSet.setCoords(json_obj.upper_left_x,json_obj.upper_left_y,json_obj.lower_right_x,json_obj.lower_right_y);
                    userResultSet.setScore(json_obj.probability);
                    userResultSet.setNumPuerta(json_obj.num_puerta)
                    console.log(this.tipoResultValor);
                    switch (this.tipoResultValor) {
                      case 'extras': {
                        userResult.setExtra(userResultSet);
                        break;
                      }
                      case 'elements': {
                        userResult.setElement(userResultSet);
                        break;
                      }
                      default: {
                        userResult.setPrediction(userResultSet);
                        break;
                      }
                    }
                }
            });
            switch (this.tipoResultValor) {
              case 'extras': {
                userResult.setElements(JSON.parse(sessionStorage.getItem('actual_elements')));
                userResult.setPredictions(JSON.parse(sessionStorage.getItem('actual_predictions')));
                break;
              }
              case 'elements': {
                userResult.setExtras(JSON.parse(sessionStorage.getItem('actual_extras')));
                userResult.setPredictions(JSON.parse(sessionStorage.getItem('actual_predictions')));
                break;
              }
              default: {
                userResult.setElements(JSON.parse(sessionStorage.getItem('actual_elements')));
                userResult.setExtras(JSON.parse(sessionStorage.getItem('actual_extras')));
                break;
              }
            }
            let requestObj=JSON.stringify(JSON.parse(userResult.getResultText()));
            require('@/assets/js/axioshelper.js')
            axios.post ("/api/results/create",requestObj, {
              headers: {
                'content-type': 'application/json',
                "Authorization": sessionStorage.getItem("token"),
                "X-Requested-With": 'XmlHttpRequest',
              },
              data: requestObj,
            })
              .then(function (response) {
                Message.SmallNoty("success","Datos guardados en caso de haber terminado pase al siguiente ticket")
                sessionStorage.setItem("nextDisabled",false)
              })
              .catch(function (error) {
                Message.SmallNoty("error","No se pudo guardar el Result: "+error)
                sessionStorage.setItem("nextDisabled",true)
                failResult=true;
              });
              if (!failResult) {
                observation="{ \"result\" : 1, \"observations\" : \"El ticket fué procesado correctamente \" }"

                axios.post ("/api/tickets/"+sessionStorage.getItem("actual")+"/reviews/create",requestObj, {
                headers: {
                  'content-type': 'application/json',
                  "Authorization": sessionStorage.getItem("token"),
                  "X-Requested-With": 'XmlHttpRequest',
                },
                data: observation,
              })
                .then(function (response) {
                  Message.SmallNoty("success","El ticket ha sido marcado como: Revisado"+error)
                })
                .catch(function (error) {
                  Message.SmallNoty("error","No se pudo guardar el Review: "+error)
                  sessionStorage.setItem("nextDisabled",true)
                });
              }
            if (sessionStorage.nextDisabled===true) {
              this.nextDisabled=true;
              requireNextTicket=true;
            } else {
              this.nextDisabled=false;
              this.requireNextTicket=false;
            }
            console.log(userResult.getResultText());

          }
        }
}
</script>