<template>
    <v-container fluid align="center">

      <v-row>
        <v-col align="right">
          <span>v1.0.0</span>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <img class="userimage" alt="UserImage" src="../assets/user.svg"></img>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
            <span>Bienvenido <b>{{username}}</b></span>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
            <span>Usted tiene el rol de: {{rol}}</span>
        </v-col>
      </v-row>

      <v-row class="d-flex justify-center" >
        <v-col sm="12" md="6" lg="4">
            <v-select
            v-model="orgselected"
            :items="orgoptions"
            v-on:change="loadProj"
            label="Organización:"
            ></v-select>
        </v-col>
      </v-row>

      <v-row class="d-flex justify-center" >
        <v-col sm="12" md="6" lg="4">
            <v-select
            v-model="projselected"
            :items="projoptions"
            v-on:change="changeProj"
            label="Proyecto:"
            class="justify-left"
            ></v-select>
        </v-col>
      </v-row>

      <v-row class="d-flex justify-center" >
        <v-col sm="12" md="6" lg="4">
            <v-btn color="success" :disabled="invalid" v-on:click="enterProj({projselected})">Seleccionar Proyecto</v-btn>
        </v-col>
      </v-row>
      
      <v-row class="d-flex justify-center" >
        <v-col sm="12" md="6" lg="4">
            <v-btn color="grey lighten-1" :disabled="invalid" v-on:click="adminProyect({projselected})">Administrar Proyecto</v-btn>
        </v-col>
      </v-row>


    </v-container>
  </template>

<script>
import Vue from 'vue'
import '@/assets/css/wellcome.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import  axios  from 'axios'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas, faSitemap, faListCheck} from '@fortawesome/free-solid-svg-icons'


library.add(fas);
library.add(faSitemap);
library.add(faListCheck);
//import auth from '@/assets/js/auth.js'
//Vue.use(auth);

export default {
        name: 'Wellcome',
        created: function () {
            //helper para axios rutas y encabezados default
            require('../assets/js/axioshelper.js')
            const orgnameselected=null;

            //Datos de organizaciones disponibles

            axios.get("/api/organizations")
                .then(response=>{
                    for (const org in response.data){
                            this.orgoptions.push({value:response.data[org]._id.$oid,text:response.data[org].name})
                        if (sessionStorage.idorganization===response.data[org]._id.$oid && sessionStorage.idorganization!="null") {
                            this.textorg=response.data[org].name;
                            sessionStorage.setItem("selNameOrganization",this.textorg);
                            this.orgnameselected=this.textorg;
                        } else if (sessionStorage.idorganization==="null") {
                             this.orgoptions.push({value:response.data[org]._id.$oid,text:response.data[org].name})
                        } 
                    }
                })
                .catch(
                    error=> {
                        console.error("mensaje",error)
                        axios.get("/api/organizations/"+sessionStorage.getItem('idorganization')+"/info")
                        .then (response=>{
                            sessionStorage.setItem('organization',response.data.name)
                            this.orgoptions.push({value:sessionStorage.getItem('idorganization'),text:sessionStorage.getItem('organization')})
                            this.orgnameselected=sessionStorage.getItem('organization');
                        })
                    }
                )

                if (sessionStorage.idorganization!="null") {
                    axios.get("/api/projects", {
                            params: {
                                organization: sessionStorage.idorganization
                            }
                        })
                        .then(response=>{
                            for (const proj in response.data){
                                if (sessionStorage.idorganization===response.data[proj].organization.$oid && sessionStorage.idorganization!="null") {
                                    this.projoptions.push({value:response.data[proj]._id.$oid,text:response.data[proj].name})
                                } else if (sessionStorage.idorganization==="null" && arg!=null) {
                                    if (arg===response.data[proj].organization.$oid) {
                                        this.projoptions.push({value:response.data[proj]._id.$oid,text:response.data[proj].name})
                                    }
                                } 
                            }
                        })
                        .catch(
                            error=> {
                                console.error("mensaje",error)
                            }
                        )
                }
          
        },
        data:() => ({
            username: sessionStorage.username,
            rol: sessionStorage.role,
            orgselected: sessionStorage.idorganization,
            orgnameselected: null,
            projselected: null,
            projnameselected: null,
            invalid:true,
            orgoptions: [
            { value: null, text: 'Seleccione una organización' },
            ],
            projoptions: [
            { value: null, text: 'Seleccione un projecto' },
            ],

        }),
        methods:{
            loadProj:function(arg) {
                let self =this;
                console.debug("Prueba");
                //Reinicia formulario de selección del proyecto
                if (self.orgselected!=null) {
                    this.projoptions=[
                    { value: null, text: 'Seleccione un projecto' },
                    ];
                    for (const org in self.orgoptions) {
                        if (self.orgselected===self.orgoptions[org].value) {
                            this.orgnameselected=self.orgoptions[org].text;
                        }
                    }
                } else {
                    this.projoptions=[
                    { value: null, text: 'Seleccione una organización primero' },
                    ];       
                }
                this.projselected= null;
                
                //Lista posibles organizaciones
                /* //Se cargaban datos anteriormente según organización seleccionada*/
                // Ahora se cargan al cargar datos de la página.
                axios.get("/api/projects", {
                    params: {
                        organization: arg
                    }
                }).then(response=>{
                    for (const proj in response.data){
                        if (sessionStorage.idorganization===response.data[proj].organization.$oid && sessionStorage.idorganization!="null") {
                            this.projoptions.push({value:response.data[proj]._id.$oid,text:response.data[proj].name})
                        } else if (/*sessionStorage.idorganization==="null" && */arg!=null) {
                            if (arg===response.data[proj].organization.$oid) {
                                this.projoptions.push({value:response.data[proj]._id.$oid,text:response.data[proj].name})
                            }
                        } 
                    }
                })
                .catch(
                    error=> {
                        console.error("mensaje",error)
                    }
                )
            },
            changeProj: function (arg) {
                let self =this;
                //Activa el boton para continuar
                if (arg!=null) {
                    self.invalid=false;
                }

                //Obtiene el nombre del Proyectos Seleccionado
                for (const proj in self.projoptions) {
                        if (self.projselected===self.projoptions[proj].value) {
                            this.projnameselected=self.projoptions[proj].text;
                        }
                    }
                    
            },
            enterProj: function (arg) {
                if (arg!=null) {
                    sessionStorage.setItem("selOrganization",this.orgselected);
                    sessionStorage.setItem("selNameOrganization",this.orgnameselected)
                    sessionStorage.setItem("selProject",arg.projselected);
                    sessionStorage.setItem("selNameProject",this.projnameselected);
                    this.$router.push({ path: '/Reviewer'})
                    this.invalid = true;
                }
            },
            adminProyect: function (arg) {
                if (arg!=null) {
                    sessionStorage.setItem("selOrganization",this.orgselected);
                    sessionStorage.setItem("selNameOrganization",this.orgnameselected)
                    sessionStorage.setItem("selProject",arg.projselected);
                    sessionStorage.setItem("selNameProject",this.projnameselected);
                    this.$router.push({ path: '/Admin'})
                    this.invalid = true;
                }
            }

        }
}
</script>