<template>
    <v-container fluid class="min-height pa-0 reviewer_class">

        <v-row class="d-flex justify-center">
            <v-col>
                <encabezado :tipoResultValor.sync="tipoResultValor"   @eventRequireNextTicket="functionUpdateRequireNextTicket" />
            </v-col>
        </v-row>

        <v-row class="row-middle">
            <v-col  class="reviewer-tools">
                <tools />
            </v-col>
            <v-col class="reviewer-canvas">
                <Pcanvas @eventUpdateResults="functionUpdateResults" @eventUpdateTags="functionUpdateTags" :tagSelected.sync="tagSelected" :tipoResultValor.sync="tipoResultValor" :requireNextTicket.sync="requireNextTicket" @eventRequireNextTicket="functionUpdateRequireNextTicket" />
            </v-col>
            <v-col class="reviewer-tags">
                <tags :tagsUsed.sync="tagsUsed" :tagsNotUsed.sync="tagsNotUsed" @eventUpdateTagSelected="functionUpdateTagSelected" @eventUpdateSelectedResult="functionUpdateSelectedResult"/>
            </v-col>
        </v-row>

        <v-row class="d-flex justify-center">
            <v-col>
                <pie />
            </v-col>
        </v-row>

    </v-container>
</template>

<script>

    import Vue from "vue";
    import '@/assets/css/login.css'
    import '@/assets/css/reviewer.css'
    import { library } from '@fortawesome/fontawesome-svg-core'
    import { fas, faLock, faUser} from '@fortawesome/free-solid-svg-icons'
    import  axios  from 'axios'
    import {sha256} from 'js-sha256'
    import jwt  from "jwt-decode";
    import Router from 'vue-router';
    import Message from '@/helpers/message.js'
    import Header from './layout/header.vue'
    import Footer from './layout/footer.vue'
    import tools_panel from './layout/tools.vue'
    import PCanvas from './layout/canvas.vue'
    import TagsLists from './layout/tags.vue'
    

    Vue.use(Router);

    library.add(fas)
    library.add(faLock)
    library.add(faUser)
    

    export default {
        name: 'Login-form',
        created: function () {

        },
        components: {
            encabezado: Header,
            pie: Footer,
            tools: tools_panel,
            Pcanvas: PCanvas,
            tags: TagsLists,
        },
        data:() => ({
            username: "javier",
            alias: "login",
            password: "hola12",
            criptopass: "",
            res: "",
            tagsUsed: [],
            tagsNotUsed: [],
            tagSelected: null,
            resultsType: null,
            tipoResultValor: null,
            requireNextTicket: null,
            form: false,
            loading: false,
            show1: false,
            show2: true,
        }),
        methods: {
            onSubmit () {
                if (!this.form) return
                this.loading = true
                this.login()
            },
            functionUpdateResults (value) {
                console.log(value)
            },
            functionUpdateTags (value) {
                this.tagsUsed = value.used;
                this.tagsNotUsed = value.notUsed;
            },
            functionUpdateTagSelected (value) {
                this.tagSelected = value.used;
            },
            functionUpdateSelectedResult (value) {
                this.tipoResultValor = value.tipoResultValor;
                console.log("yepa");
            },
            functionUpdateRequireNextTicket (value) {
                this.requireNextTicket= value;
            },
            required (v) {
                return !!v || 'Campo requerido'
            },
            getDataLogin () {
                let self =this;
                axios.defaults.baseURL="https://test.proxy.percepthor.com";
                axios.defaults.headers.get["Content-Type"]='application/x-www-form-urlencoded';
                axios.defaults.headers.get["Authorization"]=sessionStorage.getItem("token");
                axios.defaults.headers.get["X-Requested-With"]='XmlHttpRequest';
                axios.defaults.withCredentials=false;
                axios.get("/api/users/:id/info")
                .then (resp =>{
                    sessionStorage.setItem('realname',resp.data.name)
                    sessionStorage.setItem('idrole',resp.data.role.$oid)
                    if (resp.data.organization===null) {
                        sessionStorage.setItem('idorganization',null)
                    }
                })
                .catch(error=> {
                    Message.SmallNoty('error',error.message)
                })

            },
            login () {
                let self =this;
                this.criptopass=sha256(this.password);
                const postdata = {
                    alias: this.alias,
                    username: this.username,
                    password: this.criptopass
                }
                const headers = {
                    "Content-Type": "application/json;charset=UTF-8",
                    Accept: "application/json",
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Credentials": "true",
                }
                
                axios.post("https://test.proxy.percepthor.com/api/users/login",postdata)
                .then(response=>{
                    this.loading = false
                    sessionStorage.setItem(
                    "tokenSub",
                        response.data.token.substring(
                            response.data.token.indexOf(" ") + 1,
                            response.data.token.lenght
                        )
                    );
                    if (response.data.password==="Password is incorrect!") {
                        response.data.password=""
                    } else {
                        sessionStorage.setItem(
                        "token",response.data.token
                        );
                        const user = jwt(response.data.token);
                        const userrole = user.role;
                        const userid=user.id;
                        const organization=user.organization;
                        const timestamp=Date.now();
                        const username=user.username;
                        sessionStorage.setItem(
                            "username", username
                        );
                        sessionStorage.setItem(
                            "role", userrole
                        );
                        sessionStorage.setItem(
                            "userid", userid
                        );
                        sessionStorage.setItem(
                            "idorganization", organization
                        );
                        sessionStorage.setItem(
                            "isLoggedIn", true
                        );
                        sessionStorage.setItem(
                            "dateLogin", timestamp
                        );
                        Message.SmallNoty("success","Loggin successful")
                        this.getDataLogin();
                        self.$router.push('Wellcome')
                    }
                })
                .catch(
                    error=> {
                        this.loading = false
                        console.error("mensaje",error)
                        if (error.request.response) {
                            Message.SmallNoty('warning',error.request.responseText)
                        } else {
                            Message.SmallNoty('error',error.message)
                        }
                    }
                )
            },
            
        }
    }
</script>