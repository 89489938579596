//Models
import { LocalFile } from '../model/LocalFile';
import { ImageLog, LogDescription} from '../model/ImageLog';
import { Tag } from '../model/Tag'
import { RGBColor } from '../model/RGBColor'

//Vendors
import { SmallNoty, LoadingMessage, changeTextLoadingMessage, changeFooterLoadingMessage} from '../helpers/message.js'
import Swal from 'sweetalert2'




/**
 * Verify Permission of FileHandle or DirectoryHandle
 * @param {FileSystemHandle} fileHandle FileSystemHandle
 * @param {Boolean} readWrite permission read and write
 * @return {Boolean}  boolean
 */
export async function verifyPermissionHandle(fileHandle, readWrite) {
    const options = {};
    //If fileHandelExists
    if (fileHandle === null)
        return false
    if (readWrite) {
        options.mode = 'readwrite';
    }
    // Check if permission was already granted. If so, return true.
    if ((await fileHandle.queryPermission(options)) === 'granted') {
        return true;
    }
    // Request permission. If the user grants permission, return true.
    if ((await fileHandle.requestPermission(options)) === 'granted') {
        return true;
    }
    // The user didn't grant permission, so return false.
    return false;
}

/**
 * Function to added an Image log on json file <fileName.log.json> (if there isn't, it is create), only edit a file if there are changes, i
 * @param {FileSystemHandle} dirHandle DirectorySystemHandle
 * @param {string} fileName 
 * @param {PercepthorCanvas} canvas 
 * @return {Void}  void
 */
export async function generateImageLog(dirHandle, fileName, canvas) {
        //const fileHandle = await dirHandle.getFileHandle(fileName, { create: true });//Lo creamos si no existe
        const initTimeInThisImage = Number(LOCAL_STORAGE.getItem('initTimeInThisImage'));
        const currentTime = Date.parse(new Date())
        let difTimeSeconds = Math.ceil (Math.abs(currentTime - initTimeInThisImage) /1000 )
        let imageLog = new ImageLog()
        imageLog.date = currentTime
        imageLog.idImage = fileName
        imageLog.user = LOCAL_STORAGE.getItem('userName')
        imageLog.duration = difTimeSeconds
        imageLog.descriptions = []

        let objectsInsideCanvas = canvas.getObjects()
        objectsInsideCanvas.forEach((element) => { //Get changes and addeds
            if (element.type === 'percepthorArticle') {
                let logDecription = new LogDescription()
                logDecription.idArticle = element.id
                logDecription.isCorrect = null
                logDecription.acctionType = null
                logDecription.previousTag = null
                logDecription.currentTag = null

                let currentTag = element.tag
                element?.result?.originCoordinate?.probability ? logDecription.probability = element.result.originCoordinate.probability : logDecription.probability = 0

                if(element.result === null){ //Its a new percepothorarticle create by user
                    logDecription.acctionType = 'Added'
                    logDecription.previousTag = null
                    logDecription.currentTag = currentTag.className
                } else if (currentTag !== element.result.originTag) {// it already existed but it was changed
                    let previusTag = element.result.originTag
                    logDecription.acctionType = 'Changed'
                    logDecription.previousTag = previusTag.className
                    logDecription.currentTag = currentTag.className
                }
                if (element.result !== null) {
                    if (element.result.originTag !== null && element.result.isCorrect !== null){
                        let previusTag = element.result.originTag
                        logDecription.acctionType += ',Qualified'
                        logDecription.isCorrect = element.result.isCorrect
                        logDecription.previousTag = previusTag.className
                        logDecription.currentTag = null
                    }
                }
        
                if (logDecription.acctionType !== null){
                    imageLog.descriptions.push(logDecription)
                }
            }
        });
        canvas.listPercepthorArticlesDeleted.forEach((element) =>{
            let currentTag = element.result.originTag
            let logDecription = new LogDescription()
            logDecription.idArticle = element.id
            logDecription.isCorrect = null
            logDecription.acctionType = 'Deleted'
            logDecription.previousTag = currentTag.className
            logDecription.currentTag = null
             imageLog.descriptions.push(logDecription)
        })
        
        if (imageLog.descriptions.length>0){
            const fileHandle = await dirHandle.getFileHandle(fileName+'.log.json', { create: true });//Lo creamos si no existe
            const localFile = new LocalFile(fileHandle) //Usamos nuestra clase
            const previusContent = await localFile.readFile() //There is a json list
            let jsonList = []
            if(previusContent !== ""){
                jsonList = JSON.parse(previusContent); //Convert to json
            }
            jsonList.push(imageLog)//Add new log to list
            await localFile.writeFile(JSON.stringify(jsonList))
            console.log('Hubo cambios, nuevo log generado:', imageLog)
        }
    }

/**
 * Function save PercpethorImage`s PercepthorArticles on its 'res' file <imageName.imageExtension.res>
 * @param {FileSystemHandle} dirHandle DirectorySystemHandle
 * @param {string} fullNameImage 
 * @param {PercepthorCanvas} canvas 
 * @param {JSON} resFileFormat file Rees formta JSON 
 * @return {Void}  void
 */
export async function saveCurrentPercepthorImageResFile(dirHandle, fullNameImage, canvas, resFileFormat) {
        let res = await verifyPermissionHandle(dirHandle, true)
        if (res && dirHandle !== null && canvas.percepthorImage !== null) {
            const fileHandle = await dirHandle.getFileHandle(fullNameImage + '.res', { create: true });//Lo creamos si no existe
            const localFile = new LocalFile(fileHandle) //Usamos nuestra clase
            let newContent = ''
            let objectsInsideCanvas = canvas.getObjects()
            let percepthorArticles = []
            let resFormatTemporaly = null

            if (resFileFormat){ //If charged config.json file
                resFormatTemporaly = swapJSON(resFileFormat)
            }else{
                resFormatTemporaly = swapJSON(resFileFormatDefault)
            }

            objectsInsideCanvas.forEach((element) => {
                if (element.type === 'percepthorArticle') {
                    percepthorArticles.push(element)
                    let json_obj = (element.getPropertiesYOLOFormat(canvas.percepthorImage.width, canvas.percepthorImage.height))
                    for (let i = 0; i < Object.keys(resFormatTemporaly).length; i++) {
                        let o = resFormatTemporaly[i]
                        newContent += json_obj[o] + ','
                    }
                    newContent += '\n'
                }
            })
            await localFile.writeFile(newContent)
            console.log(`Cambios de la imagen [${fullNameImage}] guardados`)
            SmallNoty('success', `Tu trabajo anterior ha sido guardado`, 2000)
        } else {
            console.error('No hay carpeta seleccionada o imagen en el canvas')
            SmallNoty('error', `No se pudo guardar tu trabajo`, 2000)
        }
    }

/**
 * Swap key to value and value to key on JSON
 * @param {JSON} json simple json Object <key, value>
 * @return {JSON} JSON swaped <value, key>
 */ 
function swapJSON(json) {
    var result = {};  //Swap key to value
    for(var key in json){
        result[json[key]] = key;
    }
    return result
}

/**
 * Default 'res' format, <attribute, index>
 */ 
export const resFileFormatDefault = {
    "id": 0,
    "className": 1,
    "area": 2,
    "center_x": 3,
    "center_y": 4,
    "upper_left_x": 5,
    "upper_left_y": 6,
    "lower_right_x": 7,
    "lower_right_y":  8,
    "probability": 9,
    "width": 10,
    "height": 11,
}

/**
 * Async funtion to load image
 * @param {File} file file system
 * @return {Image}  Image on success or null on error
 */
//https://stackoverflow.com/questions/37854355/wait-for-image-loading-to-complete-in-javascript
export async function loadImage(dataImg) {
    let img = null;
    const imageLoadPromise = new Promise(resolve => {
        img = new Image();
        img.src = dataImg;
        img.onload = (e) => {
            resolve();
        };
        img.onerror = (e) => {
            console.error(`Error al cargar la imagen [${sessionStorage.getItem('actual_oid_Img')}]`, e)
            img = null; //set images as null
            resolve();
        };
    });

    await imageLoadPromise;
    return img;
}

/**
 * Async funtion to load EXIF data of image file
 * @param {File} file file system
 * @return {Image}  Image on success or null on error
 */
//https://github.com/mattiasw/ExifReader if this stops working check this https://github.com/MikeKovarik/exifr
export async function loadExifDataOfImage(file) {
    let tagsExif = null
    try {
        let imgAsArrayBuffer = null;

        const imageLoadExifDataPromise = new Promise(resolve => {
            let fr = new FileReader();
            fr.readAsArrayBuffer(file);
            fr.onload = function () {
                var data = fr.result; //It is an ArrayBuffer https://developer.mozilla.org/es/docs/Web/API/FileReader#readasarraybuffer()
                //console.log(data)
                imgAsArrayBuffer = data
                //console.log(imgAsArrayBuffer)
                resolve();
            };
            fr.onerror = (e) => {
                console.error(`Error al cargar info de la imagen [${file}]`, e)
                imgAsArrayBuffer = null; //set images as null
                resolve();
            };
        });

        await imageLoadExifDataPromise;
        
        
    } catch (error) {
        console.error('Error on reading Exif data', error);
    }
    
    return tagsExif
}

/**
 * Async Remove EXIF data from image file
 * @param {File} file file system
 * @param {FileSystemHandle} dirHandle DirectorySystemHandle
 * @return {Boolean}  Return true on success or false on error
 */
//https://jsfiddle.net/mowglisanu/frhwm2xe/3/
export async function removeExifDataFromImage(file, dirHandle) {
    let defaultFormatBlob = 'image/jpg'
    let result = false
    try {
        let imageBlob = null;

        const imageRemoveExifDataPromise = new Promise(resolve => {
            let fr = new FileReader();
            fr.readAsArrayBuffer(file);
            fr.onload = function () {
                var data = fr.result; //It is an ArrayBuffer https://developer.mozilla.org/es/docs/Web/API/FileReader#readasarraybuffer()
                
                var dv = new DataView(data); //Starting remove ECIF DATA
                var offset = 0, recess = 0;
                var pieces = [];
                var i = 0;
                /*
                https://www.media.mit.edu/pia/Research/deepview/exif.html
                Every JPEG file starts from binary value '0xFFD8', ends by binary value '0xFFD9'. 
                There are several binary 0xFFXX data in JPEG data, they are called as "Marker", and it means the period of JPEG information data. 
                0xFFD8 means SOI(Start of image), 0xFFD9 means EOI(End of image). 
                These two special Markers have no data following, the other Markers have data with it. Basic format of Marker is below.
                */
                if (dv.getUint16(offset) === 0xffd8){ //This image has Exif data
                    offset += 2;
                    var app1 = dv.getUint16(offset);
                    offset += 2;
                    while (offset < dv.byteLength){
                        if (app1 === 0xffe1){
                            
                            pieces[i] = {recess:recess,offset:offset-2};
                            recess = offset + dv.getUint16(offset);
                            i++;
                        }
                        else if (app1 === 0xffda){
                            break;
                        }
                        offset += dv.getUint16(offset);
                        app1 = dv.getUint16(offset);//var app1 = dv.getUint16(offset);
                        offset += 2;
                    }
                    if (pieces.length > 0){
                        var newPieces = [];
                        pieces.forEach(function(v){
                            newPieces.push(this.result.slice(v.recess, v.offset));
                        }, this);
                        newPieces.push(this.result.slice(recess));
                        var br = new Blob(newPieces, {type: defaultFormatBlob});
                        imageBlob = br
                    }
                }

                resolve();
            };
            fr.onerror = (e) => {
                console.error(`Error al cargar info de la imagen [${file}]`, e)
                imageBlob = null; //set images as null
                resolve();
            };
        });

        //Await fot imageBlob
        await imageRemoveExifDataPromise;

        //Override a file whith new blob content and dirHanlder
        if(imageBlob != null && dirHandle){
            const fileHandle = await dirHandle.getFileHandle(file.name, { create: true });//Lo creamos si no existe
            const writable = await fileHandle.createWritable();
            await writable.write(imageBlob);
            await writable.close();
            result = true
        }
        
    } catch (error) {
        console.error('Error on remove Exif data', error);
    }
    
    return result
}


/**
 * Get Blob data from datrURK(image_b64)
 * @param {String} dataURL base64
 * @return {Blob}  Blob on succes or null on error
 */
export function makeBlobFromDataURL(dataURL) {
    //https://stackoverflow.com/questions/16245767/creating-a-blob-from-a-base64-string-in-javascript
    if(dataURL !== null){
        const BASE64_MARKER = ';base64,';
        const parts = dataURL.split(BASE64_MARKER);
        const contentType = parts[0].split(':')[1];
        const raw = window.atob(parts[1]);
        const rawLength = raw.length;
        const uInt8Array = new Uint8Array(rawLength);
    
        for (let i = 0; i < rawLength; ++i) {
            uInt8Array[i] = raw.charCodeAt(i);
        }
    
        return new Blob([uInt8Array], { type: contentType });
    }
    else{
        return null
    }
}

export const Base64FromUrl = async (url) => {
    const data = await fetch(url);
    const blob = await data.blob();
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob); 
      reader.onloadend = () => {
        const base64data = reader.result;   
        resolve(base64data);
      }
    });
}

export function getBase64FromUrl (url) {
    return Base64FromUrl(url);
}