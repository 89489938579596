import {Message} from '../helpers/message'

export class PercepthorResult {
    constructor(organization,image,resultType) {
        this.name=null;
        this.organization=organization;
        this.image=image;
        this.resultType=resultType;
        this.nelements=0;
        this.elements=[];
        this.npredictions=0;
        this.predictions=[];
        this.nextras=0;
        this.extras=[];
        this.custom=null;

    }
  getResultText() {
        let resultText="\n{";
        resultText+="\n \"organization\": \""+this.organization+"\",";
        resultText+="\n \"image\": \""+this.image+"\",";
        resultText+="\n \"type\": \""+this.resultType+"\",";
        resultText+="\n \"n_elements\": "+this.nelements+",";
        let resultElements=JSON.stringify(this.elements);
        resultText+="\n \"elements\": "+resultElements+",";
        resultText+="\n \"n_predictions\": "+this.npredictions+",";
        let resultPredictions=JSON.stringify(this.predictions);
        resultText+="\n \"predictions\": "+resultPredictions+",";
        resultText+="\n \"n_extras\": "+this.nextras+",";
        let resultExtras=JSON.stringify(this.extras);
        resultText+="\n \"extras\": "+resultExtras+",";
        resultText+="\n \"custom\":"+this.custom;
        resultText+="\n}";
        return resultText;
    }
setPrediction(prediction) {
        if (prediction!==null && prediction!==undefined) {
            this.predictions.push(prediction);
            this.npredictions=this.predictions.length;

        } else {
            this.npredictions=0;
            this.predictions=[];
            Message.SmallNoty("error","Hay un error en el Result de Predictions")
        }

    }
setElement(element) {
        if (element!==null && element!==undefined) {
            this.elements.push(element);
            this.nelements=this.elements.length;
        } else {
            this.nelements=0;
            this.elements=[];
            Message.SmallNoty("error","Hay un error en el Result de Elements")
        }        
    }
setExtra(extra) {
        if (extra!==null && extra!==undefined) {
            this.extras.push(extra);
            this.nextras=this.extras.length;
        } else {
            this.nextras=0;
            this.extras=[];
            Message.SmallNoty("error","Hay un error en el Result de Extras")
        } 
    }
setPredictions(prediction) {
        if (prediction!==null && prediction!==undefined) {
            this.predictions=prediction;
            this.npredictions=this.predictions.length;

        } else {
            this.npredictions=0;
            this.predictions=[];
            Message.SmallNoty("error","Hay un error en el Result de Predictions")
        }

    }
setElements(element) {
        if (element!==null && element!==undefined) {
            this.elements=element;
            this.nelements=this.elements.length;
        } else {
            this.nelements=0;
            this.elements=[];
            Message.SmallNoty("error","Hay un error en el Result de Elements")
        }        
    }
setExtras(extra) {
        if (extra!==null && extra!==undefined) {
            this.extras=extra;
            this.nextras=this.extras.length;
        } else {
            this.nextras=0;
            this.extras=[];
            Message.SmallNoty("error","Hay un error en el Result de Extras")
        } 
    }


}

export class PercepthorResultSet {
    constructor () {
        this.clase="";
        this.coords=[];
        this.area=0;
        this.score=1;
        this.anaquel=0;
        this.secuencia=0;
        this.num_puerta=0;
    }
getResultSetText() {
        let resultSetText="\n{";
        resultSetText+="\n \"clase\": "+this.clase+",";
        resultSetText+="\n \"area\": "+this.area+",";
        resultSetText+="\n \"coords\": {";
        
        for (let contCords in this.coords) {
            resultSetText+="\n\""+contCords+"\": "+this.coords+","
        }
        resultSetText+="\n},"
        resultSetText+="\n \"score\": "+this.score+",";
        resultSetText+="\n \"anaquel\": "+this.anaquel+",";
        resultSetText+="\n \"secuencia\": "+this.secuencia+",";  
        resultSetText+="\n \"num_puerta\": "+this.num_puerta;      
        resultText+="\n}";
    }
setClase(clase) {
        if (clase!==null && clase!==undefined) {
            this.clase=clase;
        } else {
            this.clase="";
            Message.SmallNoty("error","Hay un error en la Clase del ResultSet")
        }
    }
setArea(area) {
        if (area!==null && area!==undefined) {
            this.area=area;
        } else {
            this.area=0;
            Message.SmallNoty("error","Hay un error en el ResultSet "+this.clase+" en el elemento area")
        }
    }
setCoords(_ulx,_uly,_lrx,_lry) {
    this.coords=[];
    this.coords["0"]=(_ulx);
    this.coords["1"]=(_uly);
    this.coords["2"]=(_lrx);
    this.coords["3"]=(_lry);
}   
setScore(score) {
        if (score!==null && score!==undefined) {
            this.score=score;
        } else {
            this.score=1;
            Message.SmallNoty("error","Hay un error en el ResultSet "+this.clase+" en el elemento score")
        }
    }
setAnaquel(anaquel) {
        if (anaquel!==null && anaquel!==undefined) {
            this.anaquel=anaquel;
        } else {
            this.anaquel=0;
            Message.SmallNoty("error","Hay un error en el ResultSet "+this.clase+" en el elemento anaquel")
        }
    } 
setSecuencia(secuencia) {
        if (secuencia!==null && secuencia!==undefined) {
            this.secuencia=secuencia;
        } else {
            this.secuencia=0;
            Message.SmallNoty("error","Hay un error en el ResultSet "+this.clase+" en el elemento secuencia")
        }
    }
setNumPuerta(num_puerta) {
        if (num_puerta!==null && num_puerta!==undefined) {
            this.num_puerta=num_puerta;
        } else {
            this.num_puerta=0;
            Message.SmallNoty("error","Hay un error en el ResultSet "+this.clase+" en el elemento num_puerta")
        }
    }
}