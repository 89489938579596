import { fabric }  from 'fabric'
import { deleteActiveObject } from '../helpers/fabricJsCustom'
import { lock_unlock_ActiveObject } from '../helpers/fabricJsCustom'
import { cloneActiveObject } from '../helpers/fabricJsCustom'
import { ENFASIS_COLOR } from '../helpers/fabricJsCustom'
import { COLOR_TRANSPARENCY } from '../helpers/fabricJsCustom'

import { roundFloat } from '../helpers/numbers'

import CloneIcon from '../assets/article/clone.svg'
import DeleteIcon from '../assets/article/delete.svg'
import LockIcon from '../assets/article/lock.svg'

//Variable to define if show labels on PercpethorArticles
export let SHOW_ARTICLES_LABELS = true

export function setSHOW_ARTICLES_LABELS(value) {
    SHOW_ARTICLES_LABELS = value
}

/**
 * Create a PercepthorArticle
 * @extends fabric.Rect
 * @param  {int}    id Id
 * @param  {string} label lable to show 
 * @param  {Result} result Result from file '.res'
 * @param  {Tag}    tag Actual Tag
 * @return {PercepthorArticle}  PercepthorArticle Object
 */
//Define class fabric.PercepthorArticle from from fabric.Rect
export var PercepthorArticle = fabric.util.createClass(
    fabric.Rect,
    {
    type: 'percepthorArticle',
    initialize: function (options){ //Constructor
        options || (options = { });
        this.callSuper('initialize', options);
        this.set('label', options.tag.className || null);
        this.set('label', null);
        this.set('julio', options.julio || 'no puso nombre');  //Atributos nuevos verificar
        this.set('id', options.id );
        this.set('result', options.result || null );  
        this.set('tag', options.tag || null );
        this.set('hoverCursor','pointer')
        this.set('strokeDashArray', [5]);
        this.set('num_puerta', options.num_puerta)
    },
    toObject: function () {
        return fabric.util.object.extend(this.callSuper('toObject'), {
            label: this.get('label'),
            julio: this.get('julio'), //quitar esta variable después
            id: this.get('id'),
            result: this.get('result'),
            tag: this.get('tag'),
            numPuerta: this.get('numPuerta'),
        });
    },
    _render: function(ctx) { //This run when execute canvas.requestRenderAll() or its objects is modificaded
        this.callSuper('_render', ctx);
        ctx.font = '18px Arial';
        ctx.fillStyle = `rgba(${ENFASIS_COLOR[0]},${ENFASIS_COLOR[1]},${ENFASIS_COLOR[2]},1)`;
        if(SHOW_ARTICLES_LABELS){
            this.set('label', this.tag.className);//Change label to new tag class name
            //this.set('label', "");//Change label to new tag class name
        }else{
            this.set('label', ""); //Empty string to clear label
        }
        ctx.fillText(this.label, -this.width/2, -this.height/2 - 2);
    },

});

/**
 * Create a PercepthorArticle from another PercepthorArticle using same attributes
 * @param  {PercepthorArticle}  percepthorArticleToClone PercepthorArticle Object
 * @param  {Number}  id id to set on new PercepthorArticle 
 * @return {PercepthorArticle}  new PercepthorArticle Object
 */
PercepthorArticle.prototype.getCloneObject = function(percepthorArticleToClone, id){
    let rgbAux = percepthorArticleToClone.tag.arrayRGBColor
    return new PercepthorArticle({
                //Attributes of Fabric.Rect
                left: percepthorArticleToClone.left + 15,
                top: percepthorArticleToClone.top + 15,
                originX: 'left',
                originY: 'top',
                width: Math.abs(percepthorArticleToClone.aCoords.tl.x - percepthorArticleToClone.aCoords.tr.x),
                height: Math.abs(percepthorArticleToClone.aCoords.tl.y - percepthorArticleToClone.aCoords.bl.y),
                objectCaching: false,
                strokeWidth: 1, //este tambien cuenta en el tamanio del objeto, en with y heigth
                strokeUniform: true,
                fill: `rgba(${rgbAux[0]}, ${rgbAux[1]}, ${rgbAux[2]}, ${COLOR_TRANSPARENCY})`,
                stroke: `rgba(${rgbAux[0]}, ${rgbAux[1]}, ${rgbAux[2]}, 1)`,
                //Extended attributes for PercepthorArticle
                label: percepthorArticleToClone.label,
                julio: percepthorArticleToClone.julio,
                id: id,
                tag: percepthorArticleToClone.tag,
                result: null,
                hoverCursor : 'pointer',
            });
}

/**
 * Refresh color fill and stroke using its Tag
 * @return {void}  void
 */
 PercepthorArticle.prototype.refreshColor = function(){
    let rgbAux = this.tag.arrayRGBColor
    this.fill = `rgba(${rgbAux[0]}, ${rgbAux[1]}, ${rgbAux[2]}, ${COLOR_TRANSPARENCY})`
    this.stroke = `rgba(${rgbAux[0]}, ${rgbAux[1]}, ${rgbAux[2]}, 1)`
}

//This function is used when cloned a PercepthorArticle Object //DONT WORK
PercepthorArticle.fromObject = function (object, callback, forceAsync) {
    //https://stackoverflow.com/questions/41568369/uncaught-typeerror-cannot-set-property-fromobject-of-undefined-fabric-custom
    return fabric.Object._fromObject('PercepthorArticle', object, callback, forceAsync, ['id','className','rgbColor','fill','stroke', 'julio'])
};

//This function change strock style when click on anything PercpethorArticle
PercepthorArticle.prototype.on('selected', function() {
    this.set('strokeDashArray', [0])
});
//This function change strock style when click on anything PercpethorArticle
PercepthorArticle.prototype.on('deselected', function() {
    this.set('strokeDashArray', [5])
});

//Define frame color and style for fabric.PercepthorArticle
PercepthorArticle.prototype.transparentCorners = false;
PercepthorArticle.prototype.cornerColor = 'rgba(${ENFASIS_COLOR[0]},${ENFASIS_COLOR[1]},${ENFASIS_COLOR[2]},1)';
PercepthorArticle.prototype.cornerStyle = 'circle';

//Quitamos la rotacion de los PercepthorArticle
const controls = PercepthorArticle.prototype.controls
const rotateControls = controls.mtr
rotateControls.visible = false

//Define funtions of botons of fabric.PercepthorArticle http://fabricjs.com/custom-control-render
PercepthorArticle.prototype.controls.deleteControl = new fabric.Control({
    x: 0.2,
    y: -0.5,
    offsetY: -20,
    offsetX: 16,
    cursorStyle: 'pointer',
    mouseUpHandler: function (event, transform) { 
        deleteActiveObject(transform.target.canvas)
    },
    render: renderFabricIconDelete,
    cornerSize: 24
});

PercepthorArticle.prototype.controls.cloneControl = new fabric.Control({
    x: -0.2,
    y: -0.5,
    offsetY: -20,
    offsetX: -16,
    cursorStyle: 'pointer',
    mouseUpHandler: (event, transform) => {cloneActiveObject(transform.target.canvas)},
    render: renderFabricIconClone,
    cornerSize: 24
});

PercepthorArticle.prototype.controls.lockControl = new fabric.Control({
    x: 0,
    y: -0.5,
    offsetY: -20,
    offsetX: 0,
    cursorStyle: 'pointer',
    mouseUpHandler: (event, transform) => {lock_unlock_ActiveObject(transform.target.canvas, !transform.target.lockMovementX)},
    render: renderFabricIconLock,
    cornerSize: 24
});

//@Javier Garrido: Verificar si esto tiene alguna interacción real
//Define other funtions for fabric.PercepthorArticle 
PercepthorArticle.prototype.getJulio = function(){ //Metodo nuevo
  console.log("Me llamo " + this.julio);
  return 1
}


//Render funtions of control icons for fabric.PercepthorArticle http://fabricjs.com/custom-control-render
function renderFabricIconDelete(ctx, left, top, styleOverride, fabricObject) {
    var svgIcon = DeleteIcon
    var imgAux = document.createElement('img');
    imgAux.src = svgIcon;
    var size = this.cornerSize;
    ctx.save();
    ctx.translate(left, top);
    ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle));
    ctx.drawImage(imgAux, -size/2, -size/2, size, size);
    ctx.restore();
}

function renderFabricIconClone(ctx, left, top, styleOverride, fabricObject) {
    var svgIcon = CloneIcon
    var imgAux = document.createElement('img');
    imgAux.src = svgIcon;
    var size = this.cornerSize;
    ctx.save();
    ctx.translate(left, top);
    ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle));
    ctx.drawImage(imgAux, -size/2, -size/2, size, size);
    ctx.restore();
}

function renderFabricIconLock(ctx, left, top, styleOverride, fabricObject) {
    var svgIcon = LockIcon
    var imgAux = document.createElement('img');
    imgAux.src = svgIcon;
    var size = this.cornerSize;
    ctx.save();
    ctx.translate(left, top);
    ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle));
    ctx.drawImage(imgAux, -size/2, -size/2, size, size);
    ctx.restore();
}

/** @Javier Garrido: Se utiliza para regresar el result
 * Get properties YOLOFormat as JSON
 * @param {Number}  imgWidth Image width
 * @param {Number}  imgHeight Image Height
 * @return {JSON}  JSON whith [id, className, area, center_x, center_y, upper_left_x, upper_left_y, lower_right_x, lower_right_y, probability] as keys
 */
 PercepthorArticle.prototype.getPropertiesYOLOFormat = function(imgWidth, imgHeight){ //Metodo nuevo
    let objStrokeWidth = this.strokeWidth //+ 1
    let objWidth  = Math.abs(this.aCoords.tl.x - this.aCoords.tr.x)
    objWidth-=objStrokeWidth //Se elimina el borde del recuadro
    let objHeigth = Math.abs(this.aCoords.tl.y - this.aCoords.bl.y)
    objHeigth-=objStrokeWidth //Se elimina el borde del recuadro
   
    let tl_x = this.aCoords.tl.x
    let tl_y = this.aCoords.tl.y
    let br_x = this.aCoords.br.x
    let br_y = this.aCoords.br.y

    let area_relative   =  roundFloat( (objWidth*objHeigth) / (imgWidth*imgHeight) )
    let upper_left_x_relative  = roundFloat(tl_x / imgWidth)
    let upper_left_y_relative  = roundFloat(tl_y / imgHeight)
    let lower_right_x_relative = roundFloat(br_x / imgWidth)
    let lower_right_y_relative = roundFloat(br_y / imgHeight)
    let center_x_relative  = roundFloat( (upper_left_x_relative + lower_right_x_relative) / 2)
    let center_y_relative  = roundFloat( (upper_left_y_relative + lower_right_y_relative) / 2)
    let width_relative = roundFloat(objWidth / imgWidth)
    let heigth_relative = roundFloat(objHeigth / imgHeight)
    let probability = 0
    if(this?.result?.originCoordinate){
        probability = this.result.originCoordinate.probability
    }
    
    let json_res = {
        "id": this.id,
        "className": this.tag.className,
        "area": area_relative ,
        "center_x": center_x_relative ,
        "center_y": center_y_relative ,
        "upper_left_x": upper_left_x_relative ,
        "upper_left_y": upper_left_y_relative ,
        "lower_right_x": lower_right_x_relative ,
        "lower_right_y": lower_right_y_relative ,
        "probability": probability,
        "width": width_relative,
        "height": heigth_relative,
        "num_puerta": this.num_puerta,
    }

    return json_res
}



/*Example use a fabric.PercepthorArticle
let x = new fabric.PercepthorArticle({
    //Attributes of Fabric.Rect
    left: 150,
    top: 200,
    originX: 'left',
    originY: 'top',
    width: 100,
    height: 100,
    objectCaching: false,
    strokeWidth: 1, //este tambien cuenta en el tamanio del objeto, en with y heigth
    strokeUniform: true,
    fill:   'rgba(50,50,50, 0.10)',
    stroke: 'rgba(50,50,50, 1)',
    //Extended attributes for PercepthorPercepthorArticle
    id: 1,
    label: '',
    rgbColor: [200,50,50],

});*/