<template>
  <v-app>
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>

// import '@/assets/css/main.css'
import '../node_modules/noty/lib/noty.css'
import '../node_modules/noty/lib/themes/bootstrap-v4.css'

export default {
  name: 'App',
  data: () => ({
    //
  }),
  beforeCreate: function() {
    if(this.$route.name == 'reviewer'){
        document.body.className = 'reviewer';
      } else {
        document.body.className = 'all';
      }
  },
  watch:{
    $route (to, from){
        // console.log(to.name,from.name)
        if(to.name == 'reviewer'){
          document.body.className = 'reviewer';
        } else {
          document.body.className = 'all';
        }
    }
  } 
};
</script>
