
/**
 * Class to create a Tag
 */
class Tag {
    /**
     * @param {Number} id Id
     * @param {String} className Class name
     * @param {Array<Number>} arrayRGBColor Array RGB
     * @param {String} urlImageExample example image url of this tag
     * @param {Boolean} isVisible used by PercepthorArticle fabricJs objects
     */
    constructor(id, className, arrayRGBColor, urlImageExample) {
        this.id = id;
        this.className = className;
        this.arrayRGBColor = arrayRGBColor;
        this.isVisible = true;
        this.urlImageExample = urlImageExample;
    }
}

export { Tag };
