<template>
    <v-container fluid class="card fill-height">
        Tickets del Proyecto
        <v-container v-for="ticket in listTickets" fluid>
            <v-card width="350" class="mx-auto">
                <v-tabs
                    v-model="tabs[ticket._id.$oid]"
                    height="40"
                    color="primary"
                    grow>
                    <v-tab :value="1">
                        <v-icon>fa-image</v-icon>
                    </v-tab>
                    <v-tab :value="2">
                        <v-icon>fa-wrench</v-icon>
                    </v-tab>
                    <v-tab :value="3">
                        <v-icon>fa-cog</v-icon>
                    </v-tab>
                </v-tabs>
                <v-container>
                    <v-row>
                        <v-col cols="2">
                            <v-tooltip right >
                            <template v-slot:activator="{ on, attrs }">
                            <v-img
                                width="60"
                                max-height="100"
                                :src="ticket.imageData"
                                v-on="on">
                            </v-img>
                            </template>
                                    <img :src="ticket.imageData" height="500">
                            </v-tooltip>
                        </v-col>
                        <v-col cols="10">
                <v-window v-model="tabs[ticket._id.$oid]">
                    <v-window-item
                        :key="0"
                        :value="0"
                        >
                        <v-card v-on:>
                                <v-container>
                                    <v-row class="dark">
                                         <v-col cols="12" class="textDash">
                                            <v-icon class="iconDash">
                                                fa-ticket
                                            </v-icon>
                                            <b>&nbsp;{{ ticket._id.$oid }}</b><br>
                                            <v-icon class="iconDash">
                                                fa-image
                                            </v-icon>
                                            <b>&nbsp;{{ ticket.image.$oid }}</b><br>
                                            <v-icon class="iconDash">
                                                fa-comment
                                            </v-icon>
                                            <b>&nbsp;{{ ticket.description }}</b><br>
                                            <v-icon class="iconDash">
                                                fa-flag
                                            </v-icon>
                                            &nbsp;Estado: <b> {{ ticketStatus[ticket.status] }}</b><br>
                                        </v-col>
                                    </v-row>
                                </v-container>
                        </v-card>
                    </v-window-item>
                    <v-window-item
                        :key="1"
                        :value="1"
                        >
                        <v-card>
                                <v-container>
                                            <v-btn
                                            prepend-icon="mdi-restart_alt"
                                            variant="outlined"
                                            >
                                            </v-btn>
                                </v-container>
                        </v-card>
                    </v-window-item>
                    <v-window-item
                        :key="2"
                        :value="2"
                        >
                        <v-card>
                                <v-container>
                                    Configuracion
                                </v-container>
                        </v-card>
                    </v-window-item>

                </v-window>
            </v-col>
            </v-row>
            </v-container>
            </v-card>
        </v-container>
    </v-container>
</template>

<script>

import '@/assets/css/tickets.css'
import { fabric }  from 'fabric'
import md5 from 'md5'
import { PercepthorArticle } from '@/model/PercepthorArticle'
import { lock_unlock_ActiveObject } from '@/helpers/fabricJsCustom.js'
import { deleteActiveObject } from "@/helpers/fabricJsCustom.js"
import { cloneActiveObject } from "@/helpers/fabricJsCustom.js"
import { Message } from "@/helpers/message.js"
import { Buffer } from 'buffer'
import  axios  from 'axios'
import Vue from 'vue'

export default {
        name: 'tools_panel',
        created: function () {
  
        },
        mounted: function () {
            let self=this;
            require('@/assets/js/axioshelper.js');
            axios.get("/api/tickets", {
                params: {
                    project: sessionStorage.getItem("selProject"),
                    skip:0,
                    limit:0,
                }
            })
            .then(async response=>{
                
                let ticketStr=JSON.stringify(response.data.tickets);
                sessionStorage.setItem("hashStatus",md5(ticketStr));
                console.log(sessionStorage.getItem("hashStatus"));
                let imageData;
                for (const index of response.data.tickets) {
                    index.imageData=await this.getImage(index.image.$oid);
                    self.listTickets.push(index);
                }
                console.log(self.listTickets);
            })
            .catch(
                error=> {
                    Message.SmallNoty("error",error)
                }
            );
            this.verificaTickets = setInterval (() => {
                axios.get("/api/tickets", {
                params: {
                    project: sessionStorage.getItem("selProject"),
                    skip:0,
                    limit:0,
                }
                })
                .then(async response=>{
                    let ticketStr=JSON.stringify(response.data.tickets);
                    if (sessionStorage.getItem('hashStatus')!==ticketStr) {
                        sessionStorage.setItem('hashStatus',ticketStr);
                        this.statusUpdate(response.data.tickets)
                        console.log("Cambio: "+sessionStorage.getItem('hashStatus'));
                    }
                    
                });
            }, 15000)
        },
        data:() =>({
          tabs: [],
          listTickets: [],  
          ticketStatus: ["Ninguno","Creado","Esperando","En revisión","Bueno","Malo","Aprovado","Error"],

        }),
        methods: {
            statusUpdate: function (tickets) {
                self=this;
                for (const ticket in tickets) {
                    this.listTickets[ticket].status=tickets[ticket].status;
                }
            },
            getImage: async function (oidImage) {
                let self=this;
                let dataImage;
                require('@/assets/js/axioshelper.js')
                await axios.get("/api/images/"+oidImage+"/data", {
                    params: {
                            
                        },
                        responseType: 'arraybuffer',
                    })
                    .then((response)=>{
                        dataImage="data:image/jpg;base64," + Buffer.from(response.data, 'binary').toString('base64');
                    });
                return dataImage;
            },
            createMode: async function () {
                const canvas = Vue.prototype.$canvas;
                canvas.hoverCursor = 'crosshair';
                canvas.cursorMode="draw";
            },
            resetSize:async function () {
                const canvas = Vue.prototype.$canvas;
                canvas.setWidth(0);
                canvas.setHeight(0);
                canvas.setWidth(canvas.wrapperEl.parentElement.parentElement.clientWidth-10);
                canvas.setHeight(canvas.wrapperEl.parentElement.parentElement.clientHeight-10);
                canvas.setFitZoom();
            },
            deleteArticles:async function () {
                const canvas = Vue.prototype.$canvas;
                deleteActiveObject(canvas);
            },
            lockArticles: function () {
                const canvas = Vue.prototype.$canvas;
                lock_unlock_ActiveObject(canvas,true);
            },   
            unlockAticles: function () {
                const canvas = Vue.prototype.$canvas;
                lock_unlock_ActiveObject(canvas,false);
            },
            cloneArticles: function () {
                let canvas=Vue.prototype.$canvas;
                cloneActiveObject(canvas);
            },
            selectModeCanvas: function () {
                let canvas=Vue.prototype.$canvas;
                canvas.hoverCursor = 'cell';
                let cadAux="selección";
                canvas.cursorMode="select";
                Message.SmallNoty('success', `Modo [${cadAux}] en el panel activado.`)
            },
            inspectModeCanvas: function () {
                //Set sleccion mode by default
                let canvas=Vue.prototype.$canvas;
                let modoActual=canvas.cursorMode;
                console.log(modoActual);
                let mode="select";
                if (modoActual === "select") {
                    mode="inspect";
                } else if (modoActual === "inspect") {
                    mode="select";
                }
                let cadAux = 'selección'
                canvas.hoverCursor = 'default'
                canvas.cursorMode = mode

                if (mode === 'select') {
                    cadAux = 'selección'
                    canvas.hoverCursor = 'cell'
                } 
                if (mode === 'inspect') {
                    cadAux = 'inspeccion'
                    canvas.hoverCursor = 'context-menu'
                }
                Message.SmallNoty('success', `Modo [${cadAux}] en el panel activado.`)
            }
        }
}
</script>